@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");

body {
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
* {
  margin: 0;
  padding: 0;
  cursor: none;
}
#no-scroll {
  overflow: hidden;
  height: 100vh;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #c850c0;
  background-image: url(../src/Assets/bars.svg);
  background-repeat: no-repeat;
  background-position: center;
}
#preloader-none {
  opacity: 0;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c05ab9cb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(174, 64, 224, 0.65);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.911);
  border-radius: 12px;
}

.cursor {
  background-color: #191919;
  opacity: 0.6;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: fixed;
  top: 0;
  left: 0;
}

@media (max-width: 767px) {
  .cursor{
    display: none;
  }
}